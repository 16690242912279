<template>
  <div class="exclusiveClients-view">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="申请书标题" prop="title">
        <el-col :xl="10">
          <el-input
              placeholder="请输入标题"
              v-model="ruleForm.title"
              clearable
              maxlength="50" show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="申请书内容" prop="details">
        <el-input type="textarea" v-model="ruleForm.details" maxlength="14000" show-word-limit></el-input>
      </el-form-item>
<!--      <el-form-item label="申请书内容" prop="details">-->
<!--        <el-input type="textarea" v-model="ruleForm.details" maxlength="14000" show-word-limit></el-input>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('ruleForm')"
        >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {selectJoinApplyList, addJoinApply,upJoinApply} from '@/api/organization.js'
export default {
  name:'membersSq',
  data() {
    return {
      disabled: false,
      ruleForm: {
        title: "",
        details: "",
      },
      rules: {
        title: [{ required: true, message: "请输入申请书标题", trigger: "blur" }],
        details: [{ required: true, message: "请输入申请书内容", trigger: "blur" }],
      },
      joinApplyId:'',
    };
  },
  mounted() {
    this.getCon()
  },
  methods: {
    getCon() {
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        type: 1,
      };
      selectJoinApplyList(ajax)
          .then(res => {
            if (res.code == 200) {
              if (res.data) {
                this.isFlag = 1
                this.ruleForm.details = res.data[0].introduce;
                this.ruleForm.title = res.data[0].title;
                this.joinApplyId = res.data[0].joinApplyId;
              }else {
                this.isFlag = 2
              }
            }
          })
    },
    submitForm(payload) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let ajax = {
            introduce: this.ruleForm.details,
            title: this.ruleForm.title,
            associationId: localStorage.getItem("associationId"),
            type:1,
            userId:localStorage.getItem("associationId"),
            ifPass:1
          }
          if (this.isFlag == 2) {
            addJoinApply(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCon()
                  }
                })
          }else {
            ajax.joinApplyId = this.joinApplyId
            upJoinApply(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCon()
                  }
                })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
/deep/ .el-textarea__inner{
  height:400px;
}
.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
</style>


