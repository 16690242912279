<template>
  <div class="customerManagement-view">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="first">
        <shInformation
            v-if="activeName == 'first'"
        />
      </el-tab-pane>
      <el-tab-pane :label="associationId==49?'基地愿景':'组织章程'" name="second">
        <membersZc
            v-if="activeName == 'second'"
        />
      </el-tab-pane>
      <el-tab-pane :label="associationId==49?'配套环境':'组织架构'" name="third">
        <membersJg
            v-if="activeName == 'third'"
        />
      </el-tab-pane>
      <el-tab-pane :label="associationId==49?'创业服务':'组织宣言'" name="fourth">
        <membersXy
            v-if="activeName == 'fourth'"
        />
      </el-tab-pane>
      <el-tab-pane label="关于我们" name="nine">
        <aboutUs
            v-if="activeName == 'nine'"
        />
      </el-tab-pane>
      <el-tab-pane :label="associationId==49?'战略合作':'组织大家庭'" name="fifth">
        <membersJt
            v-if="activeName == 'fifth'"
        />
      </el-tab-pane>
      <el-tab-pane label="入会申请书" name="sixth">
        <membersSq
            v-if="activeName == 'sixth'"
        />
      </el-tab-pane>
      <el-tab-pane label="入会协议" name="seven">
        <memberRules
            v-if="activeName == 'seven'"
        />
      </el-tab-pane>
      <el-tab-pane label="会费协议" name="eight">
        <memberMoneyRules
            v-if="activeName == 'eight'"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import memberRules from "./components/memberRules";
import memberMoneyRules from "./components/memberMoneyRules";
import aboutUs from "./components/aboutUs";
import shInformation from "./components/shInformation";
import membersZc from "./components/membersZc";
import membersJg from "./components/membersJg";
import membersJt from "./components/membersJt";
import membersXy from "./components/membersXy";
import membersSq from "./components/membersSq";
export default {
  name: "membershipManagement",
  components: {
    shInformation,
    memberMoneyRules,
    aboutUs,
    membersZc,
    membersJg,
    membersJt,
    membersXy,
    membersSq,
    memberRules
  },
  data() {
    return {
      activeName: "first",
      associationId:localStorage.getItem("associationId")
    };
  },
  // watch: {
  //   activeName(val, lod) {
  //     switch (val) {
  //       case "first":
  //         this.customerState = 1;
  //         break;
  //       case "second":
  //         this.customerState = 2;
  //         break;
  //       case "third":
  //         this.customerState = 3;
  //         break;
  //       default:
  //         return;
  //     }
  //   },
  // },
  mounted() {
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.customerManagement-view{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.el-tabs__content{
  flex: 1;
  overflow-y: scroll;
}
</style>
