<template>
  <div class="corporateInformation-view">
<!--    <el-tabs v-model="activeName">-->
<!--      <el-tab-pane label="组织信息" name="first">-->
<!--        -->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
    <el-form
        :model="members"
        :rules="rules"
        ref="members"
        label-width="auto"
        class="ruleForm"
    >
      <el-form-item label="组织名称" prop="name">
        <el-input
            clearable
            v-model="members.name"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织名称"
            maxlength="200"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="组织简称" prop="nameJc">
        <el-input
            clearable
            v-model="members.nameJc"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织简称"
            maxlength="30"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="组织联系人" prop="phone">
        <el-input
            clearable
            v-model="members.phone"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织电话"
            maxlength="20"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="组织传真" prop="fax">
        <el-input
            clearable
            v-model="members.fax"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织传真"
            maxlength="15"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="组织邮箱" prop="email">
        <el-input
            clearable
            v-model="members.email"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织邮箱"
            maxlength="30"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="客服微信" prop="weChat">
        <el-input
            clearable
            v-model="members.weChat"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入客服微信"
            maxlength="50"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="组织地址" prop="address">
        <el-input
            clearable
            v-model="members.address"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织地址"
            maxlength="50"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="所属行业" prop="industryId">
        <el-select
            v-model="members.industryId"
            style="width: 240px"
            :placeholder="members.place"
            @change="toChoose"
            clearable
        >
          <el-option
              v-for="(item) in industryList"
              :key="item.industryId"
              :label="item.industryName"
              :value="item.industryId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="公众号二维码" prop="qrCode">
        <el-upload :action="uploadUrl"
                   list-type="picture-card"
                   :class="{ disabled: uploadDisabled }"
                   :on-remove="handleRemove"
                   :on-success="handleSuccess"
                   :before-upload="beforeAvatarUploadImg"
                   :file-list="fileList"
                   :limit="1">
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <p class="fs6">图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过500k</p>
      </el-form-item>
      <el-form-item label="Logo" prop="logo">
        <el-upload :action="uploadUrl"
                   list-type="picture-card"
                   :class="{ disabled: uploadDisabled1 }"
                   :on-remove="handleRemove1"
                   :on-success="handleSuccess1"
                   :before-upload="beforeAvatarUploadImg"
                   :file-list="fileList1"
                   :limit="1">
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <p class="fs6">图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过500k</p>
      </el-form-item>
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('members')"
        >保存
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {addAssociation, selectAssociationList, fetchIndustryList, upAssociation} from '@/api/organization.js'

export default {
  name: "shInformation",
  data() {
    return {
      activeName: "first",
      disabled: false,
      members: {
        name: "",
        nameJc: "",
        phone: "",
        fax: "",
        email: "",
        weChat: "",
        address: "",
        industryId: "",
        qrCode: "",
        logo: "",
        place: '请选择所属行业',
      },
      rules: {
        qrCode: [{required: true, message: "请上传二维码"}],
        logo: [{required: true, message: "请上传logo"}],
        name: [{required: true, message: "请输入组织名称", trigger: "blur"}],
        phone: [
          {required: true, message: '请输入组织电话'},
          // {
          //   type: 'number',
          //   message: '手机号格式不正确',
          //   trigger: 'blur',
          //   transform(value) {
          //     var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
          //     if (!phonereg.test(value)) {
          //       return false
          //     } else {
          //       return Number(value)
          //     }
          //   }
          // }
        ],
        email: [{required: true, message: "请输入组织邮箱"},
          {
            message: '邮箱格式不正确',
            trigger: 'blur',
            transform(value) {
              var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
              if (!reg.test(value)) {
                return false
              } else {
                return value
              }
            }
          }],
        weChat: [{required: true, message: "请输入客服微信"},
        ],
      },
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      companyMsg: '', //公司信息
      fileList: [],
      fileList1: [],
      industryList: [],
      updateData: 2,
      associationId: '',
      industryId: ''
    };
  },
  mounted() {
    this.getIndustryList()
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
    uploadDisabled1() {
      return this.fileList1.length !== 0;
    },
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = []
      this.members.qrCode = ''
    },
    handleRemove1(file, fileList) {
      this.fileList1 = []
      this.members.logo = ''
    },
    handleSuccess1(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.members.logo = this.ossUrl + file.response.data
      this.fileList1 = fileList;
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.members.qrCode = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    toChoose() {
      this.industryId = ''
    },
    /**@method 提交表单 */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ajax = {
            associationName: this.members.name,
            aName: this.members.nameJc,
            tel: this.members.phone,
            fax: this.members.fax,
            mailbox: this.members.email,
            wechatCustomer: this.members.weChat,
            address: this.members.address,
            logo: this.members.logo,
            public2Code: this.members.qrCode,
          }
          if (this.updateData == 1) {
            ajax.associationId = this.associationId
            if (this.industryId) {
              ajax.industry = this.industryId
            } else {
              ajax.industry = this.members.industryId
            }
            upAssociation(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getIndustryList()
                  }
                })
          } else if (this.updateData == 2) {
            ajax.industry = this.members.industryId
            addAssociation(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    let associationId = res.data.associationId
                    localStorage.setItem("associationId", associationId);
                    this.getIndustryList()
                  }
                })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取公司信息
    getCompany() {
      let ajax = {
        companyId: localStorage.getItem("companyId")
      }

      selectAssociationList(ajax)
          .then(res => {
            if (res.data) {
              this.updateData = 1
              this.companyMsg = res.data.responseList[0]
              if (this.companyMsg.public2Code) {
                this.fileList = [{url: this.companyMsg.public2Code, uid: 1}]
                this.members.qrCode = this.companyMsg.public2Code
              }
              if (this.companyMsg.logo) {
                this.fileList1 = [{url: this.companyMsg.logo, uid: 2}]
                this.members.logo = this.companyMsg.logo
              }
              this.members.name = this.companyMsg.associationName
              this.members.nameJc = this.companyMsg.aname
              this.members.phone = this.companyMsg.tel
              this.members.fax = this.companyMsg.fax
              this.members.email = this.companyMsg.mailbox
              this.members.weChat = this.companyMsg.wechatCustomer
              this.members.address = this.companyMsg.address
              console.log(this.industryList[this.companyMsg.industry])
              for (let i in this.industryList) {
                if (this.industryList[i].industryId == this.companyMsg.industry) {
                  this.members.industryId = this.companyMsg.industry
                }
              }
              this.industryId = this.companyMsg.industry
              this.associationId = this.companyMsg.associationId
            }
          })
    },
    //获取行业列表
    getIndustryList() {
      fetchIndustryList()
          .then(res => {
            this.industryList = res.data
            this.getCompany()
          })
          .catch(err => {
            console.log(err)
          })
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500KB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 0;
  background: #ffffff;
  height: 80vh;
  overflow: auto;
}

/deep/ .el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  //margin-top: 24px;
  margin-left: 50px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}
</style>

