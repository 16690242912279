<template>
  <div class="exclusiveClients-view">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="顶部图片" prop="goodPhoto">
        <div style="display: flex">
          <el-upload
              :action="uploadUrl"
              :class="{
              disabled: uploadDisabled,
            }"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="goodsUpload"
              :before-upload="beforeAvatarUploadImg"
              :file-list="ruleForm.goodPhoto"
              :limit="15"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
              :visible.sync="dialogVisible"
              append-to-body
              :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
        <p class="fs6">图片上传比例为2：1，支持格式png、jpg、jpeg，大小不超过1M</p>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-col :xl="10">
          <el-input
              placeholder="请输入标题"
              v-model="ruleForm.title"
              clearable
              maxlength="50" show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="组织介绍" prop="details">
        <quill-editor
            @ready="ready($event)"
            class="ql-editor"
            style="height: 100%"
            v-model="ruleForm.details"
            ref="myQuillEditor"
            :options="editorOption"
            @change="onEditorChange($event)"
        />
        <span class="wordNumber">{{TiLength}}/4000</span>
      </el-form-item>
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('ruleForm')"
        >保存
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写文章内容'
import {selectAboutUsList, addAboutUs, upAboutUs} from '@/api/organization.js'

export default {
  name: 'aboutUs',
  data() {
    var valiIcon = (rule, value, callback) => { // 图片验证
      if (!this.ruleForm.photos) {
        callback();
      } else {
        callback();
      }
    }
    return {
      TiLength:0,
      dialogImageUrl: "",
      dialogVisible:false,
      uploadUrl: this.$store.state.uploadingUrl,
      isFlag:false,
      editorOption: quillConfig,
      disabled: false,
      ruleForm: {
        title: "",
        details: "",
        photos: "",
        goodPhoto:[]
      },
      fileList: [],
      rules: {
        title: [{required: true, message: "请输入标题", trigger: "blur"}],
        photos: [{required: true,}],
        details: [{required: true, message: "请输入组织介绍", trigger: "blur"}],
        goodPhoto: [{required: true, message: "请上传顶部图片", trigger: "blur"}]

      },
      aboutUsId:''
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 15;
    },
  },
  mounted() {
    this.getCon()
  },
  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(4000,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    getCon() {
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        type: 3,
      };
      selectAboutUsList(ajax)
          .then(res => {
            if (res.code == 200) {
              if (res.data) {
                this.isFlag = 1
                this.ruleForm.details = res.data[0].introduce;
                this.aboutUsId = res.data[0].aboutUsId;
                // this.ruleForm.photos = res.data[0].path;
                this.ruleForm.title = res.data[0].title;
                // this.fileList = [{url:res.data[0].path,uid:1}]
                let data = JSON.parse(JSON.stringify(res.data[0]));
                if (data.path) {
                  this.ruleForm.goodPhoto = data.path.split(",").map((item, index) => {
                    return {
                      url: item,
                      uid: index,
                    };
                  });
                }
              } else {
                this.isFlag = 2
              }
            }
          })
    },
    handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log(fileList)
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });

      this.ruleForm.goodPhoto = arr;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    submitForm(payload) {
      // if (!this.ruleForm.goodPhoto) {
      //   return this.$message.error('请上传顶部图片')
      // }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let arr = this.ruleForm.goodPhoto.map((item) => item.url);
          let ajax = {
            title:this.ruleForm.title,
            introduce: this.ruleForm.details,
            associationId: localStorage.getItem("associationId"),
            path:arr.join(',')
          }
          if (this.isFlag == 2) {
            addAboutUs(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCon()
                  }
                })
          }else {
            ajax.aboutUsId = this.aboutUsId
            upAboutUs(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCon()
                  }
                })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
  height: 80vh;
  overflow: auto;
}
</style>

