<template>
  <div class="exclusiveClients-view">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="入会协议规则" prop="details">
        <quill-editor
            @ready="ready($event)"
            class="ql-editor"
            style="height: 100%"
            v-model="ruleForm.details"
            ref="myQuillEditor"
            :options="editorOption"
            @change="onEditorChange($event)"
        />
        <span class="wordNumber">{{TiLength}}/14000</span>
      </el-form-item>
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('ruleForm')"
        >保存
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";


quillConfig.placeholder = '请填写文章内容'
import {selectOrganizationContentList, addOrganizationContent,upOrganizationContent} from '@/api/organization.js'

export default {
  name: 'memberRules',
  data() {
    var valiIcon = (rule, value, callback) => { // 图片验证
      if (!this.ruleForm.photos) {
        callback();
      } else {
        callback();
      }
    }
    return {
      TiLength:0,
      isFlag:null,
      editorOption: quillConfig,
      disabled: false,
      ruleForm: {
        details: "",
      },
      rules: {
        details: [{required: true, message: "请输入入会协议规则", trigger: "blur"}],
      },
      organizationContentId:'',
    };
  },
  components: {
    quillEditor,
  },
  mounted() {
    this.getCon()
  },
  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(14000,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    getCon() {
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        type: 4,
      };
      selectOrganizationContentList(ajax)
          .then(res => {
            if (res.code == 200) {
              if (res.data) {
                this.isFlag = 1
                this.ruleForm.details = res.data[0].content;
                this.organizationContentId = res.data[0].organizationContentId;
              }else {
                this.isFlag = 2
              }
            }
          })
    },
    submitForm(payload) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let ajax = {
            content: this.ruleForm.details,
            associationId: localStorage.getItem("associationId"),
            type:4
          }
          if (this.isFlag == 2) {
            addOrganizationContent(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCon()
                  }
                })
          }else {
            ajax.organizationContentId = this.organizationContentId
            upOrganizationContent(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCon()
                  }
                })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" >
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
//配置编辑器行高
.ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
  content: '行高';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='initial']::before {
  content: '默认';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before {
  content: '1';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {
  content: '1.5';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.75']::before {
  content: '1.75';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before {
  content: '2';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before {
  content: '3';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before {
  content: '4';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5']::before {
  content: '5';
}
.ql-snow .ql-picker.ql-lineheight {
  width: 70px;
}
</style>







