import { render, staticRenderFns } from "./memberMoneyRules.vue?vue&type=template&id=81270052&scoped=true&"
import script from "./memberMoneyRules.vue?vue&type=script&lang=js&"
export * from "./memberMoneyRules.vue?vue&type=script&lang=js&"
import style0 from "./memberMoneyRules.vue?vue&type=style&index=0&id=81270052&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81270052",
  null
  
)

export default component.exports